// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../stylesheets/application'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import "@fortawesome/fontawesome-free/js/all";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import 'choices.js/public/assets/styles/choices.css';
import 'choices.js/public/assets/scripts/choices.min';
import '@claviska/jquery-minicolors';
import '@claviska/jquery-minicolors/jquery.minicolors.css';

require('chart.js');
require('chartkick');

import 'chart.js';
import 'chartkick';


$(document).on('turbolinks:load', function() {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
  });

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });

  var selects = document.querySelectorAll("select");
  selects.forEach(function(select) {
    var searchEnabled = select.options.length > 3;
    var choices = new Choices(select, {
      searchEnabled: searchEnabled,
      itemSearchText: 'Please Search here',
      shouldSort: false,
    });
  });

});
