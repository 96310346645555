import consumer from "./consumer"

var url = window.location.pathname;
var room_id = url.substring(url.lastIndexOf('/') - 1);

function is_debug() {
    let urlParams = new URLSearchParams(window.location.search);
    let debug_param = urlParams.get('debug');
    if (debug_param === "true") {
        return true;
    }
    return false;
}

consumer.subscriptions.create({channel: "ChatChannel"}, {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        //Version 2024.05.24: 12:14
        // Called when there's incoming data on the websocket for this channel
        if (is_debug()) {
            debugger
        }
        console.info("chat_channel.js: Action: "+ data.channel_action + " for message:" + JSON.stringify(data));
        let conversation_id = data.message.conversation_id;
        if (data.channel_action == "new_message" )
        {
            if ( $("#chat_container_" + conversation_id).length == 0 )
            {
                try {
                    showNotification(data.message.message);
                    console.info("chat_channel.js: Notification: " + data.message.message);
                } catch(e) {
                    console.warn("chat_channel.js: Show Notification: on this page not available!", e, data);
                }
            }
            else {
                if (is_debug()) {
                    debugger
                }
                let chat_messages_element = $("#chat_messages_" + conversation_id);
                console.debug("chat_channel.js: chat_messages_element", chat_messages_element);
                let chatmessage_html = chat_messages_element.html() + "<br>" + data.raw_output;
                console.debug("chat_channel.js: chatmessage_html", chatmessage_html);
                chat_messages_element.html(chatmessage_html);
                let chat_element =  $("#chat_container_" + conversation_id);
                console.debug("chat_channel.js: chat_element", chat_element);
                chat_element.scrollTop(chat_element[0].scrollHeight);
                $("#message_message").val("");

                // response from BOT shall be displayed in context
                if (data.message.message_type == 1)
                {
                    // request context
                    $.ajax('/conversations/' + data.message.conversation_id.toString() + '/context?message_id=' + data.message.id.toString(),
                        {
                            success: function (data, status, xhr) {// success callback function
                                eval(data);
                            }
                        });
                }
            }
        }
        else if (data.channel_action == "status_change" )
        {
            var message_status = $("#status_" + data.message.id.toString()) ;
            message_status.html(data.raw_output);
            $("#chat_" + conversation_id).scrollTop($("#chat_" + conversation_id)[0].scrollHeight);
            // highlight the message with a fading color effect
            message_status.animate({backgroundColor: "#ffffff"}, 1000);
        }
            
        // response from BOT shall be displayed in context
        if (data.message.message_type == 1)
        {
            // request context
            $.ajax('/conversations/' + data.message.conversation_id.toString() + '/context?message_id=' + data.message.id.toString(),
                {
                    success: function (data, status, xhr) {// success callback function
                        eval(data);
                    }
                });
        }
    }
});
